import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize, tap } from 'rxjs';
import { ConfirmationDialogComponent } from './confirmation-dialog.component';

export type CloseCallback = () => void | undefined;
@Directive({
  selector: '[clickConfirmation]',
})
export class ClickConfirmationDirective {
  @Output() clickConfirmation = new EventEmitter<CloseCallback>();
  @Input() clickConfirmationSubscriber?: boolean;
  @Input() clickConfirmationTitle!: string;
  @Input() clickConfirmationMessage!: string;
  @Input() clickConfirmationButtonOk!: string;
  @Input() clickConfirmationButtonCancel!: string;
  @Input() clickConfirmationAllowed?: boolean = null;

  constructor(private ngbModalService: NgbModal) {}

  @HostListener('click', ['$event'])
  onClick($event: MouseEvent) {
    $event.stopPropagation();
    $event.preventDefault();

    if (!this.clickConfirmationAllowed && this.clickConfirmationAllowed !== null) {
      return this.clickConfirmation.emit();
    }

    const elementRef = this.ngbModalService.open(ConfirmationDialogComponent, {
      backdrop: 'static',
    });
    const componentInstance = <ConfirmationDialogComponent>elementRef.componentInstance;

    componentInstance.title = this.clickConfirmationTitle || 'Confirmation';
    componentInstance.message = this.clickConfirmationMessage || '';
    componentInstance.buttonCancelText = this.clickConfirmationButtonCancel || 'Cancel';
    componentInstance.buttonOkText = this.clickConfirmationButtonOk || 'Ok';

    const onCloseSub = componentInstance.confirmHandler
      .pipe(
        finalize(() => {
          onCloseSub.unsubscribe();
        }),
        tap((confirmed) => {
          if (confirmed && this.clickConfirmationSubscriber) {
            componentInstance.ladda = true;
            this.clickConfirmation.emit(() => {
              componentInstance.ladda = false;
              elementRef.close();
            });
          }

          if (confirmed && !this.clickConfirmationSubscriber) {
            elementRef.close();
            this.clickConfirmation.emit();
          }

          if (!confirmed) {
            elementRef.close();
          }
        }),
      )
      .subscribe();
  }
}

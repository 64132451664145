import { Directive, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';

@Directive({
  selector: '[infiniteScroll]',
})
export class InfiniteScrollDirective implements OnInit {
  /**
   * percentage % value from 0 - 100%, before the end of the element reaches the viewport.
   * */
  @Input() inViewport?: number;
  /**
   * <HtmlElement> to watch for scrolling events in the viewport.
   */
  @Input() inViewElement?: HTMLElement;
  /**
   * Event that fires when the scroll reaches the conditions:
   *
   * If [inViewport] is presented, then it watches when the end of the element reaches the viewport.
   * If not, the entire page will be considered when the scroll position is at the bottom.
   */
  @Output() infiniteScrollAction: EventEmitter<void> = new EventEmitter();

  private nativeElement?: HTMLElement;

  constructor(
    private window: Window,
    private elementRef: ElementRef<HTMLElement>,
  ) {}

  @HostListener('window:scroll') // for window scroll events
  onScroll() {
    /*
     * this block watches for the selected grid element and fires the event when the scroll position is at a certain
     * percentage % before the end of the grid reaches the viewport.
     * */
    if (this.inViewport && this.isXPercentInViewport(this.nativeElement, Number(this.inViewport))) {
      return this.infiniteScrollAction.emit();
    }

    /*
     * this block watches for the entire page and fires the event when the scroll position is at the bottom of the page.
     * */
    if (this.window.scrollY + this.window.innerHeight + 2 >= this.window.document.body.scrollHeight) {
      this.infiniteScrollAction.emit();
    }
  }

  ngOnInit() {
    this.nativeElement = this.inViewElement || this.elementRef.nativeElement;
  }

  // taken from: https://stackoverflow.com/questions/30943662/check-if-element-is-partially-in-viewport
  private isXPercentInViewport(element: HTMLElement, percentVisible: number) {
    const rect = element.getBoundingClientRect();
    const windowHeight = this.window.innerHeight || this.window.document.documentElement.clientHeight;

    return Math.floor(100 - ((rect.bottom - windowHeight) / rect.height) * 100) >= percentVisible;
  }
}

import { Directive, HostListener, Input } from '@angular/core';
import { GTagService } from 'core/services';

@Directive({
  selector: '[trackLinkClick]',
})
export class TrackLinkClickDirective {
  @Input() trackLinkClick: string;

  constructor(private gTagService: GTagService) {}

  @HostListener('click', ['$event.target'])
  onClick(target: HTMLAnchorElement): void {
    if (this.trackLinkClick?.length && target?.href) {
      this.gTagService.sendClickEvent(target.href, this.trackLinkClick);
    }
  }
}

import { Component, Input, ViewChild, forwardRef } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import {
  NgbCalendar,
  NgbDate,
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbDateStruct,
  NgbDatepickerConfig,
  NgbInputDatepicker,
  NgbTimeAdapter,
  NgbTimeStruct,
  NgbTimepicker,
} from '@ng-bootstrap/ng-bootstrap';
import { IDatesConfigStruct } from './models/date-struct.model';

@Component({
  exportAs: 'datepicker',
  selector: 'datepicker',
  templateUrl: './datepicker.component.html',
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => DatepickerComponent), multi: true },
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => DatepickerComponent), multi: true },
  ],
})
export class DatepickerComponent implements ControlValueAccessor, Validator {
  @Input() id: string;
  @Input() useTime?: boolean;
  @Input() meridian?: boolean;
  @Input() showWeeks?: boolean;
  @Input() placeholder?: string = '';
  @Input() color?: 'required' | 'danger' | null = null;
  @Input() display?: 'inline' | 'block' = 'block';
  @Input() defaultTimeValue?: string = '00:00';
  @ViewChild('ngDate') datepicker: NgbInputDatepicker;
  @ViewChild('ngTime') timepicker?: NgbTimepicker;

  disabled = false;
  date: NgbDateStruct;
  time: NgbTimeStruct;
  config: IDatesConfigStruct = { maxDate: this.datepickerConfig.maxDate, minDate: this.datepickerConfig.minDate };

  private onChange: (v: any) => void;
  private onTouched: () => void;
  private validatorChange: () => void;

  private readonly today = this.ngbCalendar.getToday();

  constructor(
    private dateAdapter: NgbDateAdapter<string>,
    private dateParser: NgbDateParserFormatter,
    private ngbCalendar: NgbCalendar,
    private timeAdapter: NgbTimeAdapter<string>,
    private datepickerConfig: NgbDatepickerConfig,
  ) {}

  writeValue(value: string) {
    this.date = this.dateAdapter.fromModel(value);
    this.datepicker.writeValue(value);

    if (this.useTime) {
      this.time = this.timeAdapter.fromModel(value || this.defaultTimeValue);
      this.timepicker.writeValue(value || this.defaultTimeValue);
    }
  }

  registerOnChange(fn: (value: any) => any): void {
    this.onChange = fn;
    this.datepicker.registerOnChange((v: string) => this.onDateChange(v));
    this.timepicker?.registerOnChange((v: string) => this.onTimeChange(v));
  }

  registerOnTouched(fn: () => any): void {
    this.onTouched = fn;
    this.datepicker.registerOnTouched(this.onTouched);
    this.timepicker?.registerOnTouched(this.onTouched);
  }

  registerOnValidatorChange(fn: () => void): void {
    this.validatorChange = fn;
    this.datepicker.registerOnValidatorChange(this.validatorChange);
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
    this.datepicker.setDisabledState(isDisabled);
    this.timepicker?.setDisabledState(isDisabled);
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const errors = this.datepicker.validate(control);

    if (errors) {
      if (errors.ngbDate?.minDate) {
        return {
          ngbDate: { minDate: { minDate: this.dateParser.format(this.config.minDate), actual: control.value } },
        };
      }

      if (errors.ngbDate?.maxDate) {
        return {
          ngbDate: { maxDate: { maxDate: this.dateParser.format(this.config.maxDate), actual: control.value } },
        };
      }

      return errors;
    }

    return this.validateTime(control);
  }

  validateTime(control: AbstractControl): ValidationErrors | null {
    if (this.useTime && control.value) {
      const ngbDate = NgbDate.from(this.dateAdapter.fromModel(control.value));
      const time = this.timeAdapter.fromModel(control.value);

      if (!time || !this.isTimeValid(time)) {
        return { ngbDate: { invalid: control.value, time: true } };
      }

      if (this.config.minTime && ngbDate.equals(NgbDate.from(this.config.minDate)) && this.before(time)) {
        return {
          ngbDate: {
            minTime: { minTime: this.timeAdapter.toModel(this.config.minTime), actual: this.timeAdapter.toModel(time) },
          },
        };
      }

      if (this.config.maxTime && ngbDate.equals(NgbDate.from(this.config.maxDate)) && this.after(time)) {
        return {
          ngbDate: {
            maxTime: { maxTime: this.timeAdapter.toModel(this.config.maxTime), actual: this.timeAdapter.toModel(time) },
          },
        };
      }
    }

    return null;
  }

  onDateChange(value: string) {
    this.date = this.dateAdapter.fromModel(value);

    this.onChange(
      value && (!this.useTime || this.time)
        ? value + (this.useTime ? ' ' + this.timeAdapter.toModel(this.time) : '')
        : null,
    );
  }

  onTimeChange(value: string) {
    this.time = this.timeAdapter.fromModel(value);
    this.onChange(value ? this.dateAdapter.toModel(this.date) + ' ' + this.timeAdapter.toModel(this.time) : null);
  }

  toggleCalendar() {
    this.onTouched();
    this.datepicker.toggle();
  }

  setToday() {
    const value = this.dateAdapter.toModel(this.today);

    this.date = this.today;
    this.datepicker.writeValue(value);
    this.datepicker.close();
    this.onChange(value ? value + (this.useTime && this.time ? ' ' + this.timeAdapter.toModel(this.time) : '') : null);
  }

  clear() {
    this.date = null;
    this.datepicker.writeValue(null);
    this.datepicker.close();
    this.onChange(null);
  }

  isTodayAvailable(): boolean {
    return !this.today.before(this.datepicker.minDate) && !this.today.after(this.datepicker.maxDate);
  }

  @Input()
  set minDate(value: string) {
    this.config.minDate = this.dateAdapter.fromModel(value) || this.datepickerConfig.minDate;
    this.config.minTime = this.timeAdapter.fromModel(value);

    if (this.validatorChange) {
      this.validatorChange();
    }
  }

  get minDate(): string {
    return (
      this.dateAdapter.toModel(this.config.minDate) +
      (this.useTime && this.config.minTime ? ' ' + this.timeAdapter.toModel(this.config.minTime) : '')
    );
  }

  @Input()
  set maxDate(value: string) {
    this.config.maxDate = this.dateAdapter.fromModel(value) || this.datepickerConfig.maxDate;
    this.config.maxTime = this.timeAdapter.fromModel(value);

    if (this.validatorChange) {
      this.validatorChange();
    }
  }

  get maxDate(): string {
    return (
      this.dateAdapter.toModel(this.config.maxDate) +
      (this.useTime && this.config.maxTime ? ' ' + this.timeAdapter.toModel(this.config.maxTime) : '')
    );
  }

  isTimeValid(time: NgbTimeStruct) {
    return !isNaN(time.hour) && !isNaN(time.minute) && !isNaN(time.second);
  }

  private before(ngbTime: NgbTimeStruct): boolean {
    return (
      ngbTime.hour < this.config.minTime.hour ||
      (ngbTime.hour === this.config.minTime.hour && ngbTime.minute <= this.config.minTime.minute)
    );
  }

  private after(ngbTime: NgbTimeStruct): boolean {
    return (
      ngbTime.hour > this.config.maxTime.hour ||
      (ngbTime.hour === this.config.maxTime.hour && ngbTime.minute >= this.config.maxTime.minute)
    );
  }
}

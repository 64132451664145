import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[autoFocus]',
})
export class AutoFocusDirective implements AfterViewInit {
  private setFocus?: boolean = null;
  private watching = true;

  constructor(private elementRef: ElementRef<HTMLInputElement>) {}

  ngAfterViewInit() {
    if (this.setFocus === null) {
      setTimeout(() => !this.focused && this.focus(), 1);
    }
  }

  @Input()
  set autoFocus(value: boolean | string) {
    this.setFocus = value !== '' ? !!value : null;

    // The native element could be disabled at this point
    setTimeout(() => {
      if (this.watching && this.setFocus && !this.focused) {
        this.focus();
      }
    }, 1);
  }

  focus() {
    this.watching = false;
    this.elementRef.nativeElement.focus();
  }

  get focused(): boolean {
    return document.activeElement === this.elementRef.nativeElement;
  }
}

import { Directive, ElementRef, Injector, Input } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

@Directive({
  selector: 'date-time',
})
export class DateTimeDirective extends UpgradeComponent {
  static readonly selector = 'dateTime';

  @Input() date!: string | Date;
  @Input() timeZone?: string;
  @Input() delimiter?: string;
  @Input() hideTz?: boolean;

  constructor(elementRef: ElementRef, injector: Injector) {
    super(DateTimeDirective.selector, elementRef, injector);
  }
}

import { Directive, forwardRef } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';
import { LmsValidators } from 'components/forms/validators/lms-validators.control';

@Directive({
  selector: '[noWhiteSpace]',
  providers: [{ provide: NG_VALIDATORS, useExisting: forwardRef(() => NoWhitespaceDirective), multi: true }],
})
export class NoWhitespaceDirective implements Validator {
  validate(control: AbstractControl): { [key: string]: any } | null {
    return LmsValidators.whitespace(control);
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { MarkdownRenderService } from 'core/services';

@Pipe({ name: 'markdownToHtml' })
export class MarkdownToHtmlPipe implements PipeTransform {
  constructor(private md: MarkdownRenderService) {}

  transform(text: string): string {
    if (!text) {
      return text;
    }

    return `<div class="md-view">${this.md.render(text)}</div>`;
  }
}
